@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://www.google.com/design/spec/style/color.html
$idcar-app-primary: mat.define-palette(mat.$green-palette, 600);
$idcar-app-accent: mat.define-palette(mat.$light-green-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$idcar-app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object (a Sass map containing all of the palettes).
$idcar-app-theme: mat.define-light-theme($idcar-app-primary, $idcar-app-accent, $idcar-app-warn);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($idcar-app-theme);


$idcar-primary-bg: map-get($idcar-app-primary, 400);
$idcar-primary-light-bg: map-get($idcar-app-primary, 50);
$idcar-primary-text: #444444;
$idcar-primary-link-hover: map-get($idcar-app-primary, 600);
$idcar-primary-color: map-get($idcar-app-primary, 600);
$idcar-secondary-text: #ffffff;

$idcar-success-bg: #4caf50;
$idcar-error-bg: #f44336;


$idcar-vehicle-status-ready: #4caf50;
$idcar-vehicle-status-not-ready: #f44336;
$idcar-vehicle-status-in-progress: #dad609;
$idcar-vehicle-status-in-flight: #3331b1;

html {
    height: 100%;
    margin: 0px;
    padding: 0px;
}

body {
    height: 100%;
    margin: 0px;
    padding: 0px;
    background-color: $idcar-primary-bg;
    font-family: Roboto, 'Helvetica Neue', sans-serif;
    color: $idcar-primary-text;

    .state-success {
        background-color: $idcar-success-bg;
    }

    .state-error {
        background-color: $idcar-error-bg;
    }
}

h1 {
    font-size: 24px;
    text-align: center;
}

h2 {
    font-size: 18px;

    a.idcar-link {
        font-size: 18px;
    }
}

.idcar-logo-lg {
    margin: 0px 0px 15px;
    position: relative;

    img {
        margin: 0px auto;
        display: block;
    }

    .idcar-version {
        position: absolute;
        right: 0px;
        top: 0px;
        font-size: 10px;
        color: #ffffff;
    }
}

.idcar-logo-sm {
    img {
        display: block;
    }

    .idcar-version {
        position: absolute;
        right: 0px;
        top: -2px;
        font-size: 10px;
        color: #ffffff;
        line-height: 12px;
    }
}

.idcar-text {
    font-size: 14px;
    color: $idcar-primary-text;
}

.idcar-link {
    font-size: 14px;
    color: $idcar-primary-text;
    text-decoration: none;

    &:hover {
        color: $idcar-primary-link-hover;
    }

    &.state-active {
        color: $idcar-primary-link-hover;
    }

    &.icon {
        display: flex;

        mat-icon {
            margin-right: 5px;
        }

        span {
            height: 24px;
            line-height: 24px;
            display: inline-block;
        }
    }
}

.idcar-generic-hover {
    &:hover {
        color: $idcar-primary-link-hover;
    }
}

.idcar-inverse-link {
    font-size: 14px;
    color: $idcar-primary-link-hover;
    text-decoration: none;

    &:hover {
        color: $idcar-primary-text;
    }

    &.state-active {
        color: $idcar-primary-text;
    }

    &.icon {
        display: flex;

        mat-icon {
            margin-right: 5px;
        }

        span {
            height: 24px;
            line-height: 24px;
            display: inline-block;
        }
    }
}

.idcar-menu-link {
    padding: 10px;
    display: block;
    border-bottom: 1px solid $idcar-primary-text;
    font-size: 14px;
    color: $idcar-primary-text;
    text-decoration: none;
    cursor: pointer;

    &:hover {
        color: $idcar-primary-link-hover
    }

    &.state-active {
        color: $idcar-primary-link-hover
    }

    &.icon {
        display: flex;

        mat-icon {
            margin-right: 5px;
        }

        span {
            height: 24px;
            line-height: 24px;
            display: inline-block;
        }
    }
}

.idcar-secondary-text {
    font-size: 14px;
    color: $idcar-secondary-text;
}

.idcar-secondary-link {
    font-size: 14px;
    color: $idcar-secondary-text;
    text-decoration: none;

    &:hover {
        text-decoration: underline;
    }
}

.idcar-spinner {
    display: flex;
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    background-color: rgba(255, 255, 255, 0.7);
    z-index: 10;

    mat-spinner {
        margin: auto;
    }

    .spinner-text-container {
        margin: auto;

        .spinner-text {
            padding-top: 20px;
        }
    }
}

.cdk-overlay-container {
    z-index: 100000;
}

.mat-simple-snackbar {
    white-space: pre;
}

.state-disabled {
    opacity: 0.8 !important;
    pointer-events: none !important;
}

[disabled] {
    pointer-events: none !important;
}

.idcar-primary-color {
    color: $idcar-primary-color;
}

.idcar-primary-bg {
    background-color: $idcar-primary-color;
}

.idcar-primary-shadow {
    box-shadow: 0px 0px 5px $idcar-primary-color;
}

.idcar-primary-inset-shadow {
    box-shadow: inset 0px 0px 5px $idcar-primary-color;
}

.idcar-primary-light-bg {
    background-color: $idcar-primary-light-bg;
}

.idcar-warning-color {
    color: map-get(mat.define-palette(mat.$orange-palette), 700);
}

.idcar-warning-bg-color {
    background-color: map-get(mat.define-palette(mat.$orange-palette), 700);
}

.idcar-warning-border-color {
    border-color: map-get(mat.define-palette(mat.$orange-palette), 700);
}

.idcar-warning-outline-color {
    outline-color: map-get(mat.define-palette(mat.$orange-palette), 700);
}

$large-size-button: 80px;
$large-size-icon: 48px;
$small-size-button: 24px;
$small-size-icon: 21px;

button[mat-icon-button]{
    &.icon-button-large {
        width: $large-size-button;
        height: $large-size-button;
        line-height: $large-size-button;

        .mat-icon {
            font-size: $large-size-icon;
            width: $large-size-icon;
            height: $large-size-icon;
            line-height: $large-size-icon;
        }
        .mat-button-ripple {
            font-size: inherit;
            width: inherit;
            height: inherit;
            line-height: inherit;
        }
    }

    &.icon-button-small {
        width: $small-size-button;
        height: $small-size-button;
        line-height: $small-size-button;

        .mat-icon {
            font-size: $small-size-icon;
            width: $small-size-icon;
            height: $small-size-icon;
            line-height: $small-size-icon;
        }

        .mat-button-ripple {
            font-size: inherit;
            width: inherit;
            height: inherit;
            line-height: inherit;
        }
    }
}

button[mat-raised-button],
a[mat-raised-button] {
    &.button-large {
        line-height: $large-size-button;
    }

    &.button-small {
        line-height: $small-size-button;
    }
}

mat-icon {
    &.icon-small {
        font-size: $small-size-icon;
        width: $small-size-icon;
        height: $small-size-icon;
        line-height: $small-size-icon;
    }
}

mat-form-field {
    &.field-small {
        width: 80px;
    }
}

.m-l-8 {
    margin-left: 8px;
}

.m-r-8 {
    margin-right: 8px;
}
